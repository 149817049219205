// routes
import { PATH_DASHBOARD } from '../../routes/paths';
// components
import Label from '../../components/Label';
import SvgIconStyle from '../../components/SvgIconStyle';

// ----------------------------------------------------------------------

const getIcon = (name) => (
  <SvgIconStyle src={`/static/icons/navbar/${name}.svg`} sx={{ width: '100%', height: '100%' }} />
);

const ICONS = {
  blog: getIcon('ic_blog'),
  cart: getIcon('ic_cart'),
  chat: getIcon('ic_chat'),
  mail: getIcon('ic_mail'),
  user: getIcon('ic_user'),
  kanban: getIcon('ic_kanban'),
  banking: getIcon('ic_banking'),
  calendar: getIcon('ic_calendar'),
  ecommerce: getIcon('ic_ecommerce'),
  analytics: getIcon('ic_analytics'),
  dashboard: getIcon('ic_dashboard'),
  booking: getIcon('ic_booking'),
  setting: getIcon('ic_setting')
};

const sidebarConfig = [
  // GENERAL
  // ----------------------------------------------------------------------
  {
    subheader: 'general',
    items: [
      {
        title: 'app',
        path: PATH_DASHBOARD.general.app,
        icon: ICONS.dashboard
      },
      { title: 'e-commerce', path: PATH_DASHBOARD.general.ecommerce, icon: ICONS.ecommerce },
      { title: 'POS', path: PATH_DASHBOARD.general.pos, icon: ICONS.ecommerce },
      { title: 'invoice', path: PATH_DASHBOARD.general.invoice, icon: ICONS.booking },
      { title: 'analytics', path: PATH_DASHBOARD.general.analytics, icon: ICONS.analytics },
      { title: 'settings', path: PATH_DASHBOARD.general.settings, icon: ICONS.setting }
    ]
  },

  // MANAGEMENT
  // ----------------------------------------------------------------------
  {
    subheader: 'management',
    items: [
      // MANAGEMENT : USER
      {
        title: 'user',
        path: PATH_DASHBOARD.user.root,
        icon: ICONS.user,
        children: [
          { title: 'profile', path: PATH_DASHBOARD.user.profile },
          { title: 'cards', path: PATH_DASHBOARD.user.cards },
          { title: 'list', path: PATH_DASHBOARD.user.list },
          { title: 'create', path: PATH_DASHBOARD.user.newUser },
          { title: 'edit', path: PATH_DASHBOARD.user.editById },
          { title: 'account', path: PATH_DASHBOARD.user.account }
        ]
      },
      // MANAGEMENT : CUSTOMER
      {
        title: 'customer',
        path: PATH_DASHBOARD.customer.root,
        icon: ICONS.user,
        children: [
          { title: 'cards', path: PATH_DASHBOARD.customer.cards },
          { title: 'list', path: PATH_DASHBOARD.customer.list },
          { title: 'create', path: PATH_DASHBOARD.customer.newCustomer }
        ]
      },
      // MANAGEMENT : WEBSITE
      {
        title: 'website',
        path: PATH_DASHBOARD.website.root,
        icon: ICONS.ecommerce,
        children: [
          { title: 'Header Item', path: PATH_DASHBOARD.website.homepage },
          { title: 'Home Slider', path: PATH_DASHBOARD.website.homeSlider },
          { title: 'Home Advertisement', path: PATH_DASHBOARD.website.homeAdd }
        ]
      },
      // MANAGEMENT : ORDERS
      {
        title: 'order',
        path: PATH_DASHBOARD.order.root,
        icon: ICONS.cart,
        children: [{ title: 'orders', path: PATH_DASHBOARD.order.orders }]
      },
      // MANAGEMENT : E-COMMERCE
      {
        title: 'e-commerce',
        path: PATH_DASHBOARD.eCommerce.root,
        icon: ICONS.ecommerce,
        children: [
          // { title: 'shop', path: PATH_DASHBOARD.eCommerce.shop },
          { title: 'brands', path: PATH_DASHBOARD.eCommerce.brands },
          { title: 'units', path: PATH_DASHBOARD.eCommerce.units },
          { title: 'categories', path: PATH_DASHBOARD.eCommerce.categories },
          // { title: 'product', path: PATH_DASHBOARD.eCommerce.productById },
          { title: 'product list', path: PATH_DASHBOARD.eCommerce.list },
          { title: 'create Product', path: PATH_DASHBOARD.eCommerce.newProduct },
          { title: 'create variant', path: PATH_DASHBOARD.eCommerce.newVariant },
          { title: 'suppliers', path: PATH_DASHBOARD.eCommerce.suppliers },
          { title: 'purchases', path: PATH_DASHBOARD.eCommerce.purchaseList },
          { title: 'product edit', path: PATH_DASHBOARD.eCommerce.editById },
          { title: 'inventory', path: PATH_DASHBOARD.eCommerce.inventory },
          { title: 'checkout', path: PATH_DASHBOARD.eCommerce.checkout }
          // { title: 'invoice', path: PATH_DASHBOARD.eCommerce.invoice }
        ]
      },

      // MANAGEMENT : BLOG
      {
        title: 'blog',
        path: PATH_DASHBOARD.blog.root,
        icon: ICONS.blog,
        children: [
          { title: 'posts', path: PATH_DASHBOARD.blog.posts },
          // { title: 'post', path: PATH_DASHBOARD.blog.postById },
          { title: 'new post', path: PATH_DASHBOARD.blog.newPost }
        ]
      }
    ]
  },

  // APP
  // ----------------------------------------------------------------------
  {
    subheader: 'app',
    items: [
      {
        title: 'mail',
        path: PATH_DASHBOARD.mail.root,
        icon: ICONS.mail,
        info: <Label color="error">2</Label>
      },
      { title: 'chat', path: PATH_DASHBOARD.chat.root, icon: ICONS.chat },
      { title: 'calendar', path: PATH_DASHBOARD.calendar, icon: ICONS.calendar }
      // {
      //   title: 'kanban',
      //   path: PATH_DASHBOARD.kanban,
      //   icon: ICONS.kanban
      // }
    ]
  }
];

export default sidebarConfig;
