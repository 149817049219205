import { sum, map, filter, uniqBy, reject } from 'lodash';
import { createSlice } from '@reduxjs/toolkit';
// utils
import axios from '../../utils/axios';

// ----------------------------------------------------------------------

const initialState = {
  isLoading: false,
  error: false,
  products: [],
  categories: [],
  brands: [],
  units: [],
  suppliers: [],
  purchases: [],
  orders: [],
  orderStatus: [],
  statusCounts: null,
  barCode: null,
  product: null,
  sortBy: null,
  filters: {
    gender: [],
    category: 'All',
    colors: [],
    priceRange: '',
    rating: ''
  },
  checkout: {
    activeStep: 0,
    cart: [],
    subtotal: 0,
    total: 0,
    discount: 0,
    shipping: 0,
    billing: null,
    vat: 0,
    payable: 0
  },
  headers: [],
  customItems: [],
  sliders: [],
  offers: [],
  invoice: null,
  invoices: [],
  inventories: [],
  setting: null,
  productSuggestions: [],
  blogs: []
};

const slice = createSlice({
  name: 'product',
  initialState,
  reducers: {
    // START LOADING
    startLoading(state) {
      state.isLoading = true;
    },

    // HAS ERROR
    hasError(state, action) {
      state.isLoading = false;
      state.error = action.payload;
    },

    // GET PRODUCTS
    getProductsSuccess(state, action) {
      state.isLoading = false;
      state.products = uniqBy([...state.products, ...action.payload], '_id');
      // state.products = [...state.products, ...action.payload];
    },

    // EMPTY PRODUCTS
    getProductsEmpty(state) {
      state.isLoading = false;
      state.products = [];
    },

    getProductsSuggestionSuccess(state, action) {
      state.isLoading = false;
      state.productSuggestions = [];
      state.productSuggestions = action.payload;
      // state.products = [...state.products, ...action.payload];
    },

    // GET CATEGORIES
    getCategoriesSuccess(state, action) {
      state.isLoading = false;
      state.categories = action.payload;
    },

    // GET BRANDS
    getBrandsSuccess(state, action) {
      state.isLoading = false;
      state.brands = action.payload;
    },

    // GET UNITS
    getUnitsSuccess(state, action) {
      state.isLoading = false;
      state.units = action.payload;
    },

    // GET SUPPLIERS
    getSuppliersSuccess(state, action) {
      state.isLoading = false;
      state.suppliers = action.payload;
    },

    // GET PURCHASES
    getPurchasesSuccess(state, action) {
      state.isLoading = false;
      state.purchases = action.payload;
    },

    // GET ORDERS
    getOrdersSuccess(state, action) {
      state.isLoading = false;
      state.orders = action.payload;
    },

    // GET HEADERS
    getHeadersSuccess(state, action) {
      state.isLoading = false;
      state.headers = action.payload;
    },

    // GET CUSTOM ITEMS
    getCustomItemsSuccess(state, action) {
      state.isLoading = false;
      state.customItems = action.payload;
    },

    // GET SLIDER ITEMS
    getSliderItemsSuccess(state, action) {
      state.isLoading = false;
      state.sliders = action.payload;
    },

    // GET SLIDER ITEMS
    getOffersSuccess(state, action) {
      state.isLoading = false;
      state.offers = action.payload;
    },

    // GET ORDER STATUS
    getOrderStatus(state, action) {
      state.isLoading = false;
      state.orderStatus = action.payload;
    },

    // GET STATUS COUNTS
    getStatusCounts(state, action) {
      state.isLoading = false;
      state.statusCounts = action.payload;
    },

    // GET PRODUCT BY BARCODE
    getBarcodeSuccess(state, action) {
      state.isLoading = false;
      state.barCode = action.payload;
    },

    // GET PRODUCT
    getProductSuccess(state, action) {
      state.isLoading = false;
      state.product = action.payload;
    },

    // GET INVOICE
    getInvoiceSuccess(state, action) {
      state.isLoading = false;
      state.invoice = action.payload;
    },

    // GET INVOICES
    getInvoicesSuccess(state, action) {
      state.isLoading = false;
      state.invoices = action.payload;
    },

    // GET BLOGS
    getBlogsSuccess(state, action) {
      state.isLoading = false;
      state.blogs = action.payload;
    },

    // GET INVENTORIES
    getInventoriesSuccess(state, action) {
      state.isLoading = false;
      state.inventories = uniqBy([...state.inventories, ...action.payload], '_id');
      // state.inventories = action.payload;
    },

    // GET SETTING
    getSettingSuccess(state, action) {
      state.isLoading = false;
      state.setting = action.payload;
    },

    // UPDATE ORDER STATUS
    updateOrder(state, action) {
      state.isLoading = false;
      const updatedOrders = map(state.orders, (order) => {
        if (order._id === action.payload.id) {
          console.log('Found');
          return {
            ...order,
            status: action.payload.status
          };
        }
        return order;
      });

      state.orders = updatedOrders;
    },

    // DELETE PRODUCT
    deleteProductSuccess(state, action) {
      state.isLoading = false;
      state.products = reject(state.products, { _id: action.payload });
    },

    // DELETE INVENTORY
    deleteInventorySuccess(state, action) {
      state.isLoading = false;
      state.inventories = reject(state.inventories, { _id: action.payload });
    },

    // DELETE CUSTOM ITEM
    deleteCustomItemSuccess(state, action) {
      state.isLoading = false;
      state.customItems = reject(state.customItems, { _id: action.payload });
    },

    // DELETE HOME SLIDER ITEM
    deleteHomeSliderSuccess(state, action) {
      state.isLoading = false;
      state.sliders = reject(state.sliders, { _id: action.payload });
    },

    // DELETE HOME SLIDER ITEM
    deleteOfferSuccess(state, action) {
      state.isLoading = false;
      state.offers = reject(state.offers, { id: action.payload });
    },

    // DELETE BRAND
    deleteBrandSuccess(state, action) {
      state.isLoading = false;
      state.brands = reject(state.brands, { id: action.payload });
    },

    // DELETE BRAND
    deleteUnitSuccess(state, action) {
      state.isLoading = false;
      state.units = reject(state.units, { id: action.payload });
    },

    // DELETE SUPPLIER
    deleteSupplierSuccess(state, action) {
      state.isLoading = false;
      state.suppliers = reject(state.suppliers, { id: action.payload });
    },

    // DELETE CATEGORY
    deleteCategorySuccess(state, action) {
      state.isLoading = false;
      state.categories = reject(state.categories, { _id: action.payload });
    },

    // DELETE BLOG
    deleteBlogSuccess(state, action) {
      state.isLoading = false;
      state.blogs = reject(state.blogs, { id: action.payload });
    },

    //  SORT & FILTER PRODUCTS
    sortByProducts(state, action) {
      state.sortBy = action.payload;
    },

    filterProducts(state, action) {
      state.filters.gender = action.payload.gender;
      state.filters.category = action.payload.category;
      state.filters.colors = action.payload.colors;
      state.filters.priceRange = action.payload.priceRange;
      state.filters.rating = action.payload.rating;
    },

    // CHECKOUT
    getCart(state, action) {
      const cart = action.payload;

      const subtotal = sum(
        cart.map(
          (product) =>
            (product?.color
              ? product?.color?.variant?.product?.discountedPrice
              : product?.variant?.product?.discountedPrice) * product.quantity
        )
      );
      const discount = cart.length === 0 ? 0 : state.checkout.discount;
      const shipping = cart.length === 0 ? 0 : state.checkout.shipping;
      const billing = cart.length === 0 ? null : state.checkout.billing;
      const vat = (state.setting?.vat / 100) * subtotal;
      const payable = subtotal + vat;

      state.checkout.cart = cart;
      state.checkout.discount = discount;
      state.checkout.shipping = shipping;
      state.checkout.billing = billing;
      state.checkout.subtotal = subtotal;
      state.checkout.total = subtotal - discount;
      state.checkout.vat = vat;
      state.checkout.payable = payable;
    },

    addCart(state, action) {
      const product = action.payload;
      const isEmptyCart = state.checkout.cart.length === 0;
      product.quantity = 1;

      if (isEmptyCart) {
        state.checkout.cart = [...state.checkout.cart, product];
      } else {
        state.checkout.cart = map(state.checkout.cart, (_product) => {
          const isExisted = _product._id === product._id;
          if (isExisted) {
            return {
              ..._product,
              quantity: _product.quantity + 1
            };
          }
          return _product;
        });
      }
      state.checkout.cart = uniqBy([...state.checkout.cart, product], '_id');
    },

    deleteCart(state, action) {
      const updateCart = filter(state.checkout.cart, (item) => item._id !== action.payload);

      state.checkout.cart = updateCart;
    },

    resetCart(state) {
      state.checkout.activeStep = 0;
      state.checkout.cart = [];
      state.checkout.total = 0;
      state.checkout.subtotal = 0;
      state.checkout.discount = 0;
      state.checkout.shipping = 0;
      state.checkout.billing = null;
    },

    onBackStep(state) {
      state.checkout.activeStep -= 1;
    },

    onNextStep(state) {
      state.checkout.activeStep += 1;
    },

    onGotoStep(state, action) {
      const goToStep = action.payload;
      state.checkout.activeStep = goToStep;
    },

    increaseQuantity(state, action) {
      const productId = action.payload;
      const updateCart = map(state.checkout.cart, (product) => {
        if (product._id === productId) {
          return {
            ...product,
            quantity: product.quantity + 1
          };
        }
        return product;
      });

      state.checkout.cart = updateCart;
    },

    decreaseQuantity(state, action) {
      const productId = action.payload;
      const updateCart = map(state.checkout.cart, (product) => {
        if (product._id === productId) {
          return {
            ...product,
            quantity: product.quantity - 1
          };
        }
        return product;
      });

      state.checkout.cart = updateCart;
    },

    createBilling(state, action) {
      state.checkout.billing = action.payload;
    },

    applyDiscount(state, action) {
      const { discount } = action.payload;
      const { type } = action.payload;

      if (type === 'Fixed') {
        state.checkout.discount = discount;
        state.checkout.total = state.checkout.subtotal - discount;
      } else {
        const percentDiscount = ((discount / 100) * state.checkout.subtotal).toFixed(2);
        state.checkout.discount = percentDiscount;
        state.checkout.total = state.checkout.subtotal - percentDiscount;
      }
    },

    applyShipping(state, action) {
      const shipping = action.payload;
      state.checkout.shipping = shipping;
      state.checkout.total = state.checkout.subtotal - state.checkout.discount + shipping;
    }
  }
});

// Reducer
export default slice.reducer;

// Actions
export const {
  getCart,
  addCart,
  resetCart,
  onGotoStep,
  onBackStep,
  onNextStep,
  deleteCart,
  createBilling,
  applyShipping,
  applyDiscount,
  filterProducts,
  sortByProducts,
  increaseQuantity,
  decreaseQuantity
} = slice.actions;

// ----------------------------------------------------------------------

export function getProducts(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const options = {
        method: 'GET',
        url: '/product/products',
        params
      };
      const response = await axios.request(options);
      dispatch(slice.actions.getProductsSuccess(response.data.products));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

export function getProductsSuggestions(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const options = {
        method: 'GET',
        url: '/product/products',
        params
      };
      const response = await axios.request(options);
      dispatch(slice.actions.getProductsSuggestionSuccess(response.data.products));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getHeaders() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const options = {
        method: 'GET',
        url: '/header/headers'
      };
      const response = await axios.request(options);
      dispatch(slice.actions.getHeadersSuccess(response.data.headers));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getCustomItems() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const options = {
        method: 'GET',
        url: '/custom/customItems'
      };
      const response = await axios.request(options);
      dispatch(slice.actions.getCustomItemsSuccess(response.data.customItems));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getSliderItems() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const options = {
        method: 'GET',
        url: '/slider/sliders'
      };
      const response = await axios.request(options);
      dispatch(slice.actions.getSliderItemsSuccess(response.data.sliders));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getOfferItems() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const options = {
        method: 'GET',
        url: '/offer/offers'
      };
      const response = await axios.request(options);
      dispatch(slice.actions.getOffersSuccess(response.data.offers));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getCategories() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const options = {
        method: 'GET',
        url: '/category/categories'
      };
      const response = await axios.request(options);
      dispatch(slice.actions.getCategoriesSuccess(response.data.categories));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getBlogs(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const options = {
        method: 'GET',
        url: '/blog/blogs',
        params
      };
      const response = await axios.request(options);
      dispatch(slice.actions.getBlogsSuccess(response.data.blogs));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getBrands() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const options = {
        method: 'GET',
        url: '/brand/brands?page=1&limit=500'
      };
      const response = await axios.request(options);
      dispatch(slice.actions.getBrandsSuccess(response.data.brands));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getUnits() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const options = {
        method: 'GET',
        url: '/unit/units'
      };
      const response = await axios.request(options);
      dispatch(slice.actions.getUnitsSuccess(response.data.units));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getSuppliers() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const options = {
        method: 'GET',
        url: '/supplier/suppliers'
      };
      const response = await axios.request(options);
      dispatch(slice.actions.getSuppliersSuccess(response.data.suppliers));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getPurchases() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const options = {
        method: 'GET',
        url: '/purchase/purchases'
      };
      const response = await axios.request(options);
      dispatch(slice.actions.getPurchasesSuccess(response.data.purchases));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getInventories(params) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const options = {
        method: 'GET',
        url: '/inventory/inventories',
        params
      };
      const response = await axios.request(options);
      dispatch(slice.actions.getInventoriesSuccess(response.data.inventories));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getSetting() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const options = {
        method: 'GET',
        url: '/setting/settings'
      };
      const response = await axios.request(options);
      dispatch(slice.actions.getSettingSuccess(response.data.setting));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getOrderList(status) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const options = {
        method: 'GET',
        url: `/sales/sales?status=${status}`
      };
      const response = await axios.request(options);
      dispatch(slice.actions.getOrdersSuccess(response.data.sales));
      dispatch(slice.actions.getStatusCounts(response.data.counts));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getOrderStatus() {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const options = {
        method: 'GET',
        url: '/sales/orderStatus'
      };
      const response = await axios.request(options);
      dispatch(slice.actions.getOrderStatus(response.data.orderStatus));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getProductByBarcode(barCode) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const options = {
        method: 'GET',
        url: `pos/pos/${barCode}`
      };
      const response = await axios.request(options);
      if (response.data.barCode?.variant || response.data.barCode?.color) {
        dispatch(slice.actions.addCart(response.data.barCode));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getSaleByInvoice(invoiceId) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const options = {
        method: 'GET',
        url: `sales/sale/${invoiceId}`
      };
      const response = await axios.request(options);
      dispatch(slice.actions.getInvoiceSuccess(response.data.sale));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getInvoices(searchQuery) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const options = {
        method: 'GET',
        url: 'sales/searchInvoices',
        params: { searchQuery }
      };
      const response = await axios.request(options);
      dispatch(slice.actions.getInvoicesSuccess(response.data.sales));
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function updateOrderStatus(id, status) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const options = {
        method: 'PATCH',
        url: '/sales/updateStatus',
        data: { id, status }
      };
      const response = await axios.request(options);
      if (response.data.status) {
        dispatch(slice.actions.updateOrder(response.data.sale));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function getProduct(name) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const response = await axios.get('/api/products/product', {
        params: { name }
      });
      dispatch(slice.actions.getProductSuccess(response.data.product));
    } catch (error) {
      console.error(error);
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteProduct(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const options = {
        method: 'DELETE',
        url: '/product/deleteProduct',
        data: {
          id
        }
      };
      const response = await axios.request(options);
      if (response) {
        dispatch(slice.actions.deleteProductSuccess(id));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteInventory(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const options = {
        method: 'DELETE',
        url: '/inventory/deleteInventory',
        data: {
          id
        }
      };
      const response = await axios.request(options);
      if (response) {
        dispatch(slice.actions.deleteInventorySuccess(id));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteCustomItem(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const options = {
        method: 'DELETE',
        url: '/custom/deleteCustomItem',
        data: {
          id
        }
      };
      const response = await axios.request(options);
      if (response) {
        dispatch(slice.actions.deleteCustomItemSuccess(id));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteHomeSlider(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const options = {
        method: 'DELETE',
        url: '/slider/deleteSlider',
        data: {
          id
        }
      };
      const response = await axios.request(options);
      if (response) {
        dispatch(slice.actions.deleteHomeSliderSuccess(id));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteHomeOffer(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());

    try {
      const options = {
        method: 'DELETE',
        url: '/offer/deleteOffer',
        data: {
          id
        }
      };
      const response = await axios.request(options);
      if (response) {
        dispatch(slice.actions.deleteOfferSuccess(id));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteBrand(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const ids = [];
    ids.push(id);
    try {
      const options = {
        method: 'DELETE',
        url: '/brand/deleteBrand',
        data: {
          ids
        }
      };
      const response = await axios.request(options);
      if (response) {
        dispatch(slice.actions.deleteBrandSuccess(id));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteUnit(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const ids = [];
    ids.push(id);
    try {
      const options = {
        method: 'DELETE',
        url: '/unit/deleteUnit',
        data: {
          ids
        }
      };
      const response = await axios.request(options);
      if (response) {
        dispatch(slice.actions.deleteUnitSuccess(id));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteSupplier(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    try {
      const options = {
        method: 'DELETE',
        url: '/supplier/deleteSupplier',
        data: {
          id
        }
      };
      const response = await axios.request(options);
      if (response) {
        dispatch(slice.actions.deleteSupplierSuccess(id));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteCategory(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const ids = [];
    ids.push(id);
    try {
      const options = {
        method: 'DELETE',
        url: '/category/deleteCategory',
        data: {
          ids
        }
      };
      const response = await axios.request(options);
      if (response) {
        dispatch(slice.actions.deleteCategorySuccess(id));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}

// ----------------------------------------------------------------------

export function deleteBlog(id) {
  return async (dispatch) => {
    dispatch(slice.actions.startLoading());
    const ids = [];
    ids.push(id);
    try {
      const options = {
        method: 'DELETE',
        url: '/blog/deleteBlog',
        data: {
          ids
        }
      };
      const response = await axios.request(options);
      if (response) {
        dispatch(slice.actions.deleteBlogSuccess(id));
      }
    } catch (error) {
      dispatch(slice.actions.hasError(error));
    }
  };
}
