import PropTypes from 'prop-types';
// material
import { Box } from '@material-ui/core';

// ----------------------------------------------------------------------

Logo.propTypes = {
  sx: PropTypes.object
};

export default function Logo({ sx }) {
  return (
    <Box sx={{ width: 140, height: 40, ...sx }}>
      <img className="logo-main" src="/static/brand/tbm.png" width="240" height="40" alt="Logo" />
    </Box>
  );
}
