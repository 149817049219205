// ----------------------------------------------------------------------

function path(root, sublink) {
  return `${root}${sublink}`;
}

const ROOTS_AUTH = '/auth';
const ROOTS_DASHBOARD = '/dashboard';

// ----------------------------------------------------------------------

export const PATH_AUTH = {
  root: ROOTS_AUTH,
  login: path(ROOTS_AUTH, '/login'),
  loginUnprotected: path(ROOTS_AUTH, '/login-unprotected'),
  register: path(ROOTS_AUTH, '/register'),
  registerUnprotected: path(ROOTS_AUTH, '/register-unprotected'),
  resetPassword: path(ROOTS_AUTH, '/reset-password'),
  verify: path(ROOTS_AUTH, '/verify')
};

export const PATH_PAGE = {
  comingSoon: '/coming-soon',
  maintenance: '/maintenance',
  pricing: '/pricing',
  payment: '/payment',
  about: '/about-us',
  contact: '/contact-us',
  faqs: '/faqs',
  page404: '/404',
  page500: '/500',
  components: '/components'
};

export const PATH_DASHBOARD = {
  root: ROOTS_DASHBOARD,
  general: {
    app: path(ROOTS_DASHBOARD, '/app'),
    ecommerce: path(ROOTS_DASHBOARD, '/ecommerce'),
    pos: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    analytics: path(ROOTS_DASHBOARD, '/analytics'),
    banking: path(ROOTS_DASHBOARD, '/banking'),
    booking: path(ROOTS_DASHBOARD, '/booking'),
    invoice: path(ROOTS_DASHBOARD, '/invoice'),
    settings: path(ROOTS_DASHBOARD, '/settings')
  },
  mail: {
    root: path(ROOTS_DASHBOARD, '/mail'),
    all: path(ROOTS_DASHBOARD, '/mail/all')
  },
  chat: {
    root: path(ROOTS_DASHBOARD, '/chat'),
    new: path(ROOTS_DASHBOARD, '/chat/new'),
    conversation: path(ROOTS_DASHBOARD, '/chat/:conversationKey')
  },
  calendar: path(ROOTS_DASHBOARD, '/calendar'),
  kanban: path(ROOTS_DASHBOARD, '/kanban'),
  user: {
    root: path(ROOTS_DASHBOARD, '/user'),
    profile: path(ROOTS_DASHBOARD, '/user/profile'),
    cards: path(ROOTS_DASHBOARD, '/user/cards'),
    list: path(ROOTS_DASHBOARD, '/user/list'),
    newUser: path(ROOTS_DASHBOARD, '/user/new'),
    editById: path(ROOTS_DASHBOARD, '/user/holla/edit'),
    account: path(ROOTS_DASHBOARD, '/user/account')
  },
  customer: {
    root: path(ROOTS_DASHBOARD, '/customer'),
    cards: path(ROOTS_DASHBOARD, '/customer/cards'),
    list: path(ROOTS_DASHBOARD, '/customer/list'),
    newCustomer: path(ROOTS_DASHBOARD, '/customer/new')
  },
  website: {
    root: path(ROOTS_DASHBOARD, '/website'),
    homepage: path(ROOTS_DASHBOARD, '/website/homepage'),
    homeSlider: path(ROOTS_DASHBOARD, '/website/homeSlider'),
    homeAdd: path(ROOTS_DASHBOARD, '/website/homeAdd')
  },
  order: {
    root: path(ROOTS_DASHBOARD, '/order'),
    orders: path(ROOTS_DASHBOARD, '/order/orders')
  },
  eCommerce: {
    root: path(ROOTS_DASHBOARD, '/e-commerce'),
    shop: path(ROOTS_DASHBOARD, '/e-commerce/shop'),
    brands: path(ROOTS_DASHBOARD, '/e-commerce/brands'),
    units: path(ROOTS_DASHBOARD, '/e-commerce/units'),
    categories: path(ROOTS_DASHBOARD, '/e-commerce/categories'),
    product: path(ROOTS_DASHBOARD, '/e-commerce/product/:name'),
    productById: path(ROOTS_DASHBOARD, '/e-commerce/product/nike-air-force-1-ndestrukt'),
    list: path(ROOTS_DASHBOARD, '/e-commerce/list'),
    newProduct: path(ROOTS_DASHBOARD, '/e-commerce/product/new'),
    editById: path(ROOTS_DASHBOARD, '/e-commerce/product/product/edit'),
    checkout: path(ROOTS_DASHBOARD, '/e-commerce/checkout'),
    invoice: path(ROOTS_DASHBOARD, '/e-commerce/invoice'),
    newBrand: path(ROOTS_DASHBOARD, '/e-commerce/brand/new'),
    newUnit: path(ROOTS_DASHBOARD, '/e-commerce/unit/new'),
    newCategory: path(ROOTS_DASHBOARD, '/e-commerce/category/new'),
    newVariant: path(ROOTS_DASHBOARD, '/e-commerce/variant/new'),
    suppliers: path(ROOTS_DASHBOARD, '/e-commerce/suppliers'),
    newSupplier: path(ROOTS_DASHBOARD, '/e-commerce/supplier/new'),
    purchaseList: path(ROOTS_DASHBOARD, '/e-commerce/purchases'),
    newPurchase: path(ROOTS_DASHBOARD, '/e-commerce/purchase/new'),
    orders: path(ROOTS_DASHBOARD, '/e-commerce/orders'),
    inventory: path(ROOTS_DASHBOARD, '/e-commerce/inventory'),
    inventoryHistory: path(ROOTS_DASHBOARD, '/e-commerce/inventoryHistory')
  },
  blog: {
    root: path(ROOTS_DASHBOARD, '/blog'),
    posts: path(ROOTS_DASHBOARD, '/blog/posts'),
    post: path(ROOTS_DASHBOARD, '/blog/post/:title'),
    postById: path(ROOTS_DASHBOARD, '/blog/post/apply-these-7-secret-techniques-to-improve-event'),
    newPost: path(ROOTS_DASHBOARD, '/blog/new-post')
  }
};

export const PATH_DOCS = 'https://docs-minimals.vercel.app/introduction';
